import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { TemplateLegal } from '../templates';
import { Heading, Lead, Paragraph, List, ListItem, Link } from '@arturpol/style-guide/src/components';
import { Ruler, Email, Section } from '../components';
export const meta = {
  title: 'Privacy Policy',
  type: 'legal',
  hint: 'Privacy Policy',
  order: 96,
  seoTitle: 'Privacy Policy',
  seoDescription: 'The privacy policy of the systemsforhumans.com website.'
};
export const _frontmatter = {};
const layoutProps = {
  meta,
  _frontmatter
};
const MDXLayout = TemplateLegal;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Heading importance={1} size={6} mdxType="Heading">Website Privacy Policy</Heading>
    <Ruler mdxType="Ruler" />
    <Section mdxType="Section">
    <Lead mdxType="Lead">The privacy of our website’s users is very important to us, which is why we have developed this Privacy Policy so that you can understand how we collect, process, disclose and use your personal information.</Lead>
    </Section>
    <Section mdxType="Section">
    <Paragraph mdxType="Paragraph">Artur Polkowski IT Services as the Administrator of the service and personal data ensures that:</Paragraph>
    <List type="ordered" mdxType="List">
        <ListItem mdxType="ListItem">Data shall always be collected for specified and clearly defined purposes and to the extent necessary to achieve those purposes.</ListItem>
        <ListItem mdxType="ListItem">Personal data shall be kept for as long as necessary to achieve these purposes.</ListItem>
        <ListItem mdxType="ListItem">Personal data shall be collected lawfully and fairly at all times, where appropriate, with the knowledge or consent of the data subject.</ListItem>
        <ListItem mdxType="ListItem">Personal data is protected with reasonable safeguards against loss or theft, as well as against unauthorized access, disclosure, copying, use, or modification.</ListItem>
        <ListItem mdxType="ListItem">Its clients are provided with information about the rules and practices relating to personal data management.</ListItem>
    </List>
    </Section>
    <Section subtitle="Article 1" title="Privacy and processing information" mdxType="Section">
    <List type="ordered" mdxType="List">
        <ListItem mdxType="ListItem">The administrator of personal data is Artur Polkowski IT Services with its registered office in Murowana Goslina (61-095), 10 Brzozowa Street, NIP: 7772997209.</ListItem>
        <ListItem mdxType="ListItem">Artur Polkowski IT Services as a personal data administrator (hereinafter referred to as "Administrator") attaches great importance to the protection of privacy and confidentiality of personal data of Users who make their data available in electronic form through forms available on the website in the domain systemsforhumans.com (hereinafter referred to as "systemsforhumans.com"), the rules of which are specified in the Terms of Use (hereinafter referred to as "Terms of Use").</ListItem>
        <ListItem mdxType="ListItem">The Data Protection Officer of Artur Polkowski IT Services is Artur Polkowski who can be contacted via email: <Email type="legal" mdxType="Email" />.</ListItem>
        <ListItem mdxType="ListItem">The Administrator protects personal data against unauthorized access and processing in violation of applicable regulations.</ListItem>
        <ListItem mdxType="ListItem">Visitors to systemsforhumans.com can browse through subpages of the website without providing personal data.</ListItem>
    </List>
    </Section>
    <Section subtitle="Article 2" title="Grounds for processing personal data" mdxType="Section">
    <List type="ordered" mdxType="List">
        <ListItem mdxType="ListItem">Personal data shall be processed by the Controller in accordance with the law, in particular, Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data and repealing Directive 95/46/EC (hereinafter referred to as 'GDPR').</ListItem>
        <ListItem mdxType="ListItem">
            <Paragraph mdxType="Paragraph">Personal data are processed to:</Paragraph>
            <List type="ordered" mdxType="List">
                <ListItem mdxType="ListItem">answer questions addressed to the Administrator through the contact form available on systemsforhumans.com website (according to article 6.1.f of the GDPR);</ListItem>
                <ListItem mdxType="ListItem">dispatch of marketing content, including information about planned workshops, business information, newsletter or dispatch of eBooks and other information on the basis of the consent given (Article 6(1)(a) of the GDPR);</ListItem>
                <ListItem mdxType="ListItem">
                    <Paragraph mdxType="Paragraph">recruitment, including:</Paragraph>
                    <List type="unordered" mdxType="List">
                        <ListItem mdxType="ListItem">to establish and maintain contact with the Candidate in relation to the application documents submitted, pursuant to Article 6(1)(b) of the GDPR, i.e. in relation to taking action at the request of the data subject before concluding a contract,</ListItem>
                        <ListItem mdxType="ListItem">carrying out and resolving the recruitment process based on Article 6(1)(b) of the GDPR, i.e. taking the necessary actions at the request of the data subject before concluding the contract - in the scope of data indicated in Article 221 §1 of the Labor Code and on the basis of the Candidate's consent, i.e. Article 6(1)(a) of the GDPR and in the scope of data beyond the catalog indicated in Article 221 §1 of the Labor Code,</ListItem>
                        <ListItem mdxType="ListItem">to take account of the Candidate's application documents in future recruitment processes on the basis of his/her voluntary agreement (Article 6(1)(a) of the GDPR).</ListItem>
                    </List>
                </ListItem>
                <ListItem mdxType="ListItem">establishing contact in order to prepare, at the Customer's request, an offer of the Administrator's services and/or products tailored to the Customer's needs (Art. 6.1.b of the GDPR),</ListItem>
                <ListItem mdxType="ListItem">adapting and developing the website's functionalities, including its structure and content to the needs of Internet users, creating aggregated statistics, and preserving the security and quality of services provided by the service - based on the legitimate interest of the administrator (Article 6(1)(f) of the GDPR);</ListItem>
                <ListItem mdxType="ListItem">investigation or safeguard against possible claims (according to Article 6(1)(f) of the GDPR).</ListItem>
            </List>
        </ListItem>
        <ListItem mdxType="ListItem">Regardless of the purpose of the processing, providing the data is voluntary, however, failure to do so may prevent, depending on the specific case, the conclusion of a contract, use of selected services within the service, and its functionality or receipt of marketing content.</ListItem>
        <ListItem mdxType="ListItem">The User should not provide the Administrator with personal data of third parties. In case of transfer of third parties' data, the User is obliged to sign a declaration that he has the consent of third parties to transfer the data to the Administrator.</ListItem>
    </List>
    </Section>
    <Section subtitle="Article 3" title="Scope of the processing of personal data" mdxType="Section">
    <Paragraph mdxType="Paragraph">The Administrator processes personal data only to the extent necessary to achieve a strictly defined purpose, in accordance with the information indicated below:</Paragraph>
    <List type="ordered" mdxType="List">
        <ListItem mdxType="ListItem">Sending a message through the contact form, among others: email address and telephone number and all other information that the User will provide of his own free will in the addressed message;</ListItem>
        <ListItem mdxType="ListItem">Sending newsletters, commercial and business information and eBooks: name and surname, email address, telephone number, among others;</ListItem>
        <ListItem mdxType="ListItem">Preparation of offers: name and surname, email address, telephone number, and other information contained in the message sent through the contact form;</ListItem>
        <ListItem mdxType="ListItem">Customization and development of website functionality: IP addresses collected during Internet connections for technical purposes related to server administration.</ListItem>
    </List>
    <Paragraph mdxType="Paragraph">The Administrator does not make automated decisions on the basis of data collected about Users.</Paragraph>
    </Section>
    <Section subtitle="Article 4" title="Data retention period" mdxType="Section">
    <Paragraph mdxType="Paragraph">Personal data shall be kept only for the time necessary to achieve the specific purpose for which it was transmitted or to ensure compliance, as set out below:</Paragraph>
    <List type="ordered" mdxType="List">
        <ListItem mdxType="ListItem">Personal data collected for the purpose of answering questions asked via the contact form will be processed no longer than 12 months after the last contact;</ListItem>
        <ListItem mdxType="ListItem">Data collected for the purpose of sending marketing content, newsletters, commercial and business information, and eBooks will be processed until the withdrawal of consent by the User;</ListItem>
        <ListItem mdxType="ListItem">Personal data collected for the purposes of recruitment will be processed for the duration of the recruitment process, and if the Candidate agrees to leave the data for the purposes of future recruitments, they will be stored until the withdrawal of the consent, but not longer than 24 months from the date of its granting;</ListItem>
        <ListItem mdxType="ListItem">Personal data collected for the purposes of preparing a personalized offer for products and/or services will be processed for the duration of the offer negotiation, and after its completion for 12 months counted from the date of the last contact, or they will feed the Administrator's customer database in order to execute the concluded agreement.</ListItem>
    </List>
    </Section>
    <Section subtitle="Article 5" title="Recipients of personal data" mdxType="Section">
    <List type="ordered" mdxType="List">
        <ListItem mdxType="ListItem">Users' data may be made available to entities authorized to receive data in accordance with applicable laws, including relevant judicial authorities.</ListItem>
        <ListItem mdxType="ListItem">Personal data may be transferred to entities cooperating with the Administrator on the basis of appropriate agreements, including selected marketing agencies and partners providing technical services (development and maintenance of IT systems and websites).</ListItem>
        <ListItem mdxType="ListItem">
            <Paragraph mdxType="Paragraph">Personal data may be transferred to a third country/international organization, as described in the points below:</Paragraph>
            <List type="ordered" mdxType="List">
                <ListItem mdxType="ListItem">Users' personal data may be processed on servers located outside their country of residence;</ListItem>
                <ListItem mdxType="ListItem">Users' personal data may be transferred outside the European Economic Area (EEA) to a third country, i.e. the USA, to entities meeting the required level of protection under the European Commission's decision of 12 July 2016, the so-called Privacy Shield. These entities comply with the rules established by the U.S. Department of Commerce under the EU-US Privacy Shield Framework Programs governing the collection, use, and retention of personal data from European Union Member States.</ListItem>
            </List>
        </ListItem>
    </List>
    </Section>
    <Section subtitle="Article 6" title="Rights of persons" mdxType="Section">
    <List type="ordered" mdxType="List">
        <ListItem mdxType="ListItem">The user is obliged to provide complete, current, and up-to-date data.</ListItem>
        <ListItem mdxType="ListItem">
            <Paragraph mdxType="Paragraph">Every user whose personal data is processed by the Administrator is entitled to:</Paragraph>
            <List type="ordered" mdxType="List">
                <ListItem mdxType="ListItem">access the data,</ListItem>
                <ListItem mdxType="ListItem">correct the data,</ListItem>
                <ListItem mdxType="ListItem">deletion of the data,</ListItem>
                <ListItem mdxType="ListItem">restrict the processing of data,</ListItem>
                <ListItem mdxType="ListItem">transfer the data,</ListItem>
                <ListItem mdxType="ListItem">object to the processing of data which takes place on the basis of the Administrator's legitimate interest,</ListItem>
                <ListItem mdxType="ListItem">withdraw consent (where processing is based on consent) at any time without affecting the lawfulness of processing carried out on the basis of consent before the withdrawal.</ListItem>
            </List>
        </ListItem>
        <ListItem mdxType="ListItem">You can exercise the rights set out in the above section by sending an appropriate request, providing your user name and email address to: <Email type="legal" mdxType="Email" />.</ListItem>
        <ListItem mdxType="ListItem">The user has the right to appeal to the supervisory authority if he considers that the processing of personal data violates the rules of the GDPR.</ListItem>
        <ListItem mdxType="ListItem">In order to unsubscribe from the communication, please contact us via: <Email type="legal" mdxType="Email" /> email.</ListItem>
    </List>
    </Section>
    <Section subtitle="Article 7" title="Cookie policy" id="cookie" mdxType="Section">
    <List type="ordered" mdxType="List">
        <ListItem mdxType="ListItem">Artur Polkowski IT Services collects data in logs, which are used only for service administration, and these data are not transferred to third parties.</ListItem>
        <ListItem mdxType="ListItem">
            <Paragraph mdxType="Paragraph">Following established practice, we store HTTP queries addressed to our server. The resources viewed are identified by their URLs. The information collected in the logs is:</Paragraph>
            <List type="unordered" mdxType="List">
                <ListItem mdxType="ListItem">The public IP address of the computer from which the query came,</ListItem>
                <ListItem mdxType="ListItem">User station name - identification implemented by HTTP protocol, if possible,</ListItem>
                <ListItem mdxType="ListItem">User name given during the authorization process,</ListItem>
                <ListItem mdxType="ListItem">Time for the inquiry to arrive,</ListItem>
                <ListItem mdxType="ListItem">The first line of the HTTP request,</ListItem>
                <ListItem mdxType="ListItem">The HTTP response code,</ListItem>
                <ListItem mdxType="ListItem">The number of bytes sent by the server,</ListItem>
                <ListItem mdxType="ListItem">The URL of the page previously visited by the user, in case the Company's website was accessed via a link,</ListItem>
                <ListItem mdxType="ListItem">Information about User browser,</ListItem>
                <ListItem mdxType="ListItem">Information about errors that occurred in the execution of HTTP transactions.</ListItem>
            </List>
        </ListItem>
        <ListItem mdxType="ListItem">This data is not connected to specific people browsing the Administrator's pages.</ListItem>
        <ListItem mdxType="ListItem">To ensure the highest quality of service, the Administrator periodically analyses log files to determine which pages are visited most often, which web browsers are used, whether the structure of the site does not contain errors, etc.</ListItem>
        <ListItem mdxType="ListItem">The collected logs are stored for 24 months as auxiliary material used to administer the service - based on cookie files, statistics may be generated which do not contain any features identifying the persons visiting systemsforhumans.com.</ListItem>
        <ListItem mdxType="ListItem">
            <Paragraph mdxType="Paragraph">The following types of cookies are used on the website:</Paragraph>
            <List type="ordered" mdxType="List">
                <ListItem mdxType="ListItem">security cookies e.g. used to detect misuse of authentication;</ListItem>
                <ListItem mdxType="ListItem">"performance" cookies, enabling the collection of information on how to use the website;</ListItem>
                <ListItem mdxType="ListItem">"functional" cookies, enabling "remembering" the settings selected by the User and personalizing the User's interface, e.g. in terms of the selected language or region from which the User comes from, font size, the appearance of the website, etc.</ListItem>
            </List>
        </ListItem>
        <ListItem mdxType="ListItem">Cookies are not used to process or store personal data. They cannot be used to directly identify the User and do not make any configuration changes in the browser or telecommunication devices.</ListItem>
    </List>
    </Section>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      